import { NavLink } from '../ui/navLink';
import { CountryCode } from '../../lib/constants';
import { IProduct, IProductTiles } from '../../interfaces';
import styles from './TrendingCategory.module.css';
import Image from "next/image";
import { useState } from 'react';
import { useGlobalContext } from '../../lib/context';
import CustomSlideArrow from '../shared/customSlideArrow/CustomSlideArrow';
import imageLoader from '../../lib/helpers/imageLoader';
import { pushRoute } from '@/lib/helpers/common';
export function TrendingCategory(props: { countryCode: CountryCode, products: IProduct[] }) {
    const siteURL = process.env.SITE_URL;
    const { props: { isMobileView } } = useGlobalContext();
    const [active, setActive] = useState(props.products[0] && props.products[0].categorySlug)
    const designIdeaURL = `${siteURL}/${props.countryCode}/interior-design-ideas`;
    if (!props.products || props.products.length == 0) return null;
    const handleClick = (e: any, CategorySlug: string) => {
        setActive(CategorySlug)
    }
    const scrolltile = (direction: string, categoryNameId: string) => {
        const productBxId: any = document.getElementById(categoryNameId)
        if (productBxId != null) {
            if (direction === "prev") {
                productBxId.scrollLeft -= isMobileView ? 50 : 130;
            } else {
                productBxId.scrollLeft += isMobileView ? 50 : 130;
            }
        }
    }
    return <>
        <section className="section">
            <div className={`container ${styles.containerFluid}`}>
                <div className={styles.toptrandHead}>
                    <h2 className="font26 text222 mb16">Design Ideas for Every Space</h2>
                    <p className="font16 text666 mb46">Because every corner holds a unique design potential.</p>
                </div>
                <div className={styles.typeOfModular}>
                    {props && props.products.length >= 7 &&
                        <span className={styles.leftArrow} onClick={() => scrolltile("prev", "designIdeaForEverySpace")}>
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1 1 7l6 6" stroke="#222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                    }
                    <ul id='designIdeaForEverySpace'>
                        {props.products.map((productlist, index) =>
                            <li key={index} className={active == productlist.categorySlug ? styles.active : ""} onClick={(e) => handleClick(e, productlist.categorySlug)}>{productlist.categoryName}</li>)}
                    </ul>
                    {props && props.products.length >= 7 &&
                        <span className={styles.rightArrow} onClick={() => scrolltile("next", "designIdeaForEverySpace")} >
                            <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="m1 13 6-6-6-6" stroke="#222" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </span>
                    }
                </div>
                <div className={styles.mainBx}>
                    {props.products.map((f, idx) =>
                        <div key={idx} className={`${active == f.categorySlug ? "" : "displayNone"}`}>
                            <div className={`${styles.topTrandsMianbox} mb30`} key={idx} id={f.categorySlug}>
                                {f.categoryProduct && f.categoryProduct.map((productTiles: IProductTiles, idx2: number) =>
                                    <div key={idx2} className={styles.topTrandstliles} onClick={() => pushRoute(`${designIdeaURL + "/" + f.categorySlug + "/" + productTiles.productSlug}`)}>
                                        <figure>
                                            <Image
                                                loader={imageLoader}
                                                src={productTiles.relativePath}
                                                height={240}
                                                width={310}
                                                className="imgFluid"
                                                alt={productTiles.title}
                                                title={productTiles.title}
                                            />
                                        </figure>
                                        <span onClick={() => pushRoute(designIdeaURL + "/" + f.categorySlug + "/" + productTiles.productSlug)} key={idx} className={styles.topTrendBx}>
                                            <div className={styles.topTrandstext}>
                                                {
                                                    productTiles.productSlug.includes('-pid-') ?
                                                        <NavLink href={`${designIdeaURL + "/" + f.categorySlug + "/" + productTiles.productSlug}`} key={idx2} className={styles.topTrandstliles} >
                                                            <p className="text222 lineClamp" >{productTiles.title}</p>
                                                        </NavLink> :
                                                        <p className="text222 lineClamp" >{productTiles.title}</p>
                                                }
                                            </div>
                                        </span>
                                    </div>)}
                                {f.categoryProduct && f.categoryProduct.length >= 7 ?
                                    <div className={styles.desginlaberyCardsclick}>
                                        <p className={`font18 lineHeight20 text222 fbold mb12 ${styles.suBtext}`}>Explore More {` `}
                                            <NavLink href={`${designIdeaURL + "/" + f.categorySlug}`} >
                                                {f.categoryName} Designs
                                            </NavLink>
                                        </p>
                                        <span className={styles.viewAllBtn} onClick={() => pushRoute(`${designIdeaURL + "/" + f.categorySlug}`)}>View All</span>
                                        <div className={styles.disdinlibryimg}><Image
                                            src={'/assets/images/Desdinlabirysvg.svg'}
                                            alt="Desdinlabirysvg"
                                            width={174}
                                            height={132}
                                        /> </div>
                                    </div> : ""}
                            </div>
                            {isMobileView && f.categoryProduct && f.categoryProduct.length > 1 ? <CustomSlideArrow slideId={f.categorySlug} slideTileWebWidth={130} slideTileMobWidth={290} /> : ""}
                            <button onClick={() => pushRoute(`${designIdeaURL + "/" + f.categorySlug}`)} className={`${styles.fullWidthBtn} btn btnPrimery btnCenter`}>Explore More Designs</button>
                        </div>
                    )}
                </div>
            </div>
        </section>
    </>;
}